@charset "UTF-8";

.main-container{
    background-image: url(../assets/img/me.png);
    height: 700px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
} 

.main-container h1{
    color: black;
    padding: 0px;
    margin: 50px;
    text-align: right;
    font-size: 42px;
    padding-left: 30%;
    padding-right: 20%;
    padding-top: 10%;
    margin-left: 30;
    margin-right: 0;
    bottom: 0; 
    left: 30;
}

.quote {
    font-family: 'Tellular';
    font-style: italic;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.text-separator {
    border-right: 10px solid rgb(204, 203, 203);   
}

.hr {
    border: 0 none;
    height: 2px;
    /* Set the hr color */
    color: rgb(151, 151, 151); /* old IE */
    background-color: rgb(151, 151, 151); /* Modern Browsers */
}